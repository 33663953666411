import React from "react";

import "./customforminline.scss";
const CustomFormInline = ({ status, message, onValidated }) => {
  let email;
  const submit = () => {
    email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  };

  return (
    <div>
      <form
        className="flex flex-col w-full"
        onSubmit={(e) => e.preventDefault()}
      >
        {status === "sending" && <div>Sending...</div>}
        {status === "error" && (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === "success" && <div>Thanks for subscribing!</div>}

        <input
          type="email"
          className="w-full pt-2 mb-2 text-red placeholder-current uppercase bg-transparent border-b border-current md:border-b-2"
          placeholder="Enter email"
          ref={(node) => (email = node)}
        />
        <footer className="flex items-end mt-3">
          {status !== "sending" && status !== "success" && (
            <button
              type="submit"
              className="w-auto ml-auto uppercase"
              onClick={submit}
            >
              Submit
            </button>
          )}
        </footer>
      </form>
    </div>
  );
};

export default CustomFormInline;
