import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomFormInline from "./CustomFormInline";
const NewsletterInline = () => {
  const url =
    "https://store.us6.list-manage.com/subscribe/post?u=c6c8d2a5aae6e123b0df25ace&id=e5628432a2";
  return (
    <div className="w-full text-orange">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomFormInline
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default NewsletterInline;
