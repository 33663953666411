import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo/Seo";
import RichText from "../components/text/RichText";
import NewsletterInline from "../components/newsletter/NewsletterInline";
const TermsAndConditions = ({ data: { terms } }) => {
  return (
    <Layout>
      <Seo title="TERMS AND CONDITIONS" />
      <div className="-mt-4 text-lg leading-none lg:text-5xl md:-mt-8 md:text-4xl">
        {terms.sections.map((section) => {
          return (
            <section className="my-4 md:my-8" key={section._key}>
              <header className="flex items-center w-full mb-2 md:mb-8">
                <div className="w-4/12 pr-12 md:w-1/2">
                  <svg
                    className="w-auto h-3 md:h-8"
                    viewBox="0 0 14 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 2.9H12.2C12 2.8 11.7 2.5 11.4 2.2L10.5 1.2C10.1 0.8 9.7 0.4 9.2 0H10.5C11.2 0.8 12.8 2.5 13.7 3.3C12.8 4.2 11.3 5.9 10.5 6.6H9.2C9.6 6.2 10 5.8 10.4 5.4L10.9 4.9L12.1 3.7H0V2.9Z"
                      className="fill-current"
                    />
                  </svg>
                </div>
                <div className="flex-1 py-4 -ml-0.5">{section.title}</div>
              </header>

              <div
                className={`normal-case ${
                  section.links.length > 0 ? "" : "md:pr-12"
                }`}
              >
                {section.content &&
                  section.content.map((child) => {
                    child.markDefs = [];
                    return <RichText key={child._key} blocks={child} />;
                  })}
                {section.links.length > 0 && (
                  <div className="flex items-center w-full mb-4 md:mb-8">
                    <div className="flex flex-col w-4/12 pr-12 md:w-1/2">
                      {section.links.map((link) => {
                        return <span key={link._key}>{link.label}</span>;
                      })}
                    </div>
                    <div className="flex flex-col items-start justify-start flex-1 w-1/2 space-y-2 leading-none">
                      {section.links.map((link) => {
                        return (
                          <span key={link._key}>
                            {link._type === "link" ? (
                              <a
                                href={link.link}
                                className="block leading-none border-b-2 border-transparent hover:border-red"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span className="block min-w-0 truncate -my-0.5 md:-my-1">
                                  {link.title}
                                </span>
                              </a>
                            ) : (
                              <Link
                                className="block leading-none border-b-2 border-transparent hover:border-red"
                                to="/about"
                              >
                                {link.refLink.title}
                              </Link>
                            )}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
                {section.newsletter && (
                  <div className="flex items-center w-full mt-4 md:mt-8">
                    <NewsletterInline />
                  </div>
                )}
              </div>
            </section>
          );
        })}

        <footer className="my-4 md:my-8">
          <Link to="/" className="flex items-center w-full mb-4 md:mb-8">
            <span className="w-4/12 pr-12 md:w-1/2">
              <svg
                className="w-auto h-3 transform rotate-180 md:h-8"
                viewBox="0 0 14 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 2.9H12.2C12 2.8 11.7 2.5 11.4 2.2L10.5 1.2C10.1 0.8 9.7 0.4 9.2 0H10.5C11.2 0.8 12.8 2.5 13.7 3.3C12.8 4.2 11.3 5.9 10.5 6.6H9.2C9.6 6.2 10 5.8 10.4 5.4L10.9 4.9L12.1 3.7H0V2.9Z"
                  className="fill-current"
                />
              </svg>
            </span>
            <span className="flex-1"> Back </span>
          </Link>
        </footer>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;

export const query = graphql`
  {
    terms: sanityTerms {
      title
      sections {
        title
        newsletter
        _key
        links {
          ... on SanityLink {
            _key
            _type
            link
            label
            title
          }
          ... on SanityInternalLink {
            _key
            _type
            title
            label
          }
        }
        content {
          style
          list
          children {
            text
            marks
            _type
            _key
          }
          _type
          _key
        }
      }
    }
  }
`;
